import React from "react"

import * as pdfData from "../../static/strategie/strategy.pdf"
import PdfPreview from "../components/pdf-preview/pdf-preview"

class StrategiePage extends React.Component {

  render() {
    return (
      <PdfPreview pdfData={pdfData}></PdfPreview>
    )
  }

}

export default StrategiePage